@8px: 8px;
@16px: 16px;
@24px: 24px;
@32px: 32px;
@48px: 48px;
@64px: 64px;

@font-regular: "editor-regular";
@font-bold: "editor-bold";

@color-text: lighten(#000, 30%); 
@color-border: #EEE;
@color-strong: #000;

.listing {
    list-style-type: none;
    padding: 0px;
    margin: 0px;
    
    li {
        display: flex;
        align-items: center;
        height: 25px;
        border: 1px solid @color-border;
        border-width: 0 0 1px 0;
        padding: @8px 0;
        font-size: 13px;

        &.feature {
           img {
               margin-right: @16px;
           } 
        }
    }
}
@import "./shared.less";

html, body {
    margin: 0px;
    padding: 0px;
}

@page-width: 800px;

#particles-wrapper {
    position: absolute;
    width: 100%;;
}

#page-wrapper {
    position: relative;
    width: @page-width;
    margin: @64px auto;
    font-family: "editor-regular";
    color: @color-text;

    #header {
        color: @color-strong;
        text-align: center;
        padding-bottom: @8px;

        h1 {
            margin-bottom: 0px;
            font-family: "editor-bold";
            font-weight: 100;
        }
        h4 {
            margin-top: @8px;
            margin-bottom: @32px;
            font-weight: 100;
        }
    }

    #tinymde-root {
        min-height: 500px;
        color: @color-strong;
        height: 400px;
    }

    #content {
        padding: @24px @32px;
        
        .columns {
            display: flex;
            .column {
                flex: 1;
                margin-right: @32px;
            }
            .column:last-child {
                margin-right: 0px;
            }
        }

        h3 {
            font-size: 17px;
            color: @color-strong;
            padding-bottom: @16px;
            border: 1px solid @color-border;
            border-width: 0 0 1px 0;
            margin-bottom: 0px !important;
        }
    }

    #footer {
        text-align: center;
        margin-top: @32px;
        font-size: 11px;
        color: @color-text;
        a {
            color: @color-strong !important;
        }
    }

    @media screen and (max-width: @page-width) {
        width: 100%;
        margin: @32px auto;

        #header {
            h1 {
                font-size: 28px;
            }
            h4 {
                font-size: 14px;
            }
        }
        
        #content {
            .columns {
                flex-direction: column;
                .column {
                    margin: 0px;
                    h3 {
                        margin-top: @32px;
                    }

                    &:first-child {
                        h3 {
                            margin-top: @16px;
                        }
                    }
                }
            }
        }
    }
}
@import "../shared.less";

#shortcuts {
    @color-key: #FFF;

    .shortcut {
        user-select: none;

        .key {
            background-color: @color-key;
            display: inline-block;
            border: 1px solid darken(@color-border, 10%);
            min-width: 15px;
            text-align: center;
            padding: 4px;
            border-radius: 4px;
            box-shadow: inset 0 -2px @color-border;
        }
    }

    .description {
        margin-left: 16px;
    }
}